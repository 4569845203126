import { get } from 'lodash';
import { ELSLoggingService } from '@els/els-ui-common-react';
import { fetchCrosswalkUser } from '../../apis/eols-user-management-service/eols-user-management-service.utilities';
import { SystemType } from '../../apis/eols-user-management-service/eols-user-management-service.dtos';
import { AppContextState } from './AppProvider.component';
import { removeCookies } from '../../pages/app-link-redirect/app-link-redirect.utilities';
import {
  AnalyticsAction,
  AnalyticsActionProps
} from '../../models/analytics.models';
import {
  fetchAppLinkData,
  fetchHashLink,
  getLinkNavigation,
  fetchPerformanceAppExamResultsUrl,
} from '../../apis/eols-app-link/eols-app-link.utilities';
import { scrubProps } from '../../utilities/analytics.utilities';
import { getDefaultActionProps } from './app-state-selectors.utilities';
import {
  fetchGroupedFeatureFlags,
  fetchGroupFeatureFlag,
  postGroupFeatureFlag
} from '../../apis/eols-features-api/eols-features-api.utilities';
import {
  getActiveABTestFlags,
  getRandomABTestFlavor
} from '../../utilities/featureFlag.utilities';
import { FeatureFlagsGroupedDto } from '../../apis/eols-features-api/eols-features-api.dtos';
import { fetchCourseSection as fetchCourseSectionService } from '../../apis/eols-course-crud/eols-course-crud.utilities';
import { AppState } from './app-state.types';
import {
  fetchAppFeatureSettings,
  fetchCurrentCourseProgramDetails,
  fetchNgnExamItems,
  fetchNgnExamRemediation,
  fetchRemediationData,
  fetchStudyPackets
} from '../../apis/hesi-app-facade-service/hesi-app-facade-service.utilities';
import {
  AppFeatureSettingsDto,
  CourseProgramDetailsDto,
  NgnExamItemDto,
  NgnRemediationItemDto,
  RemediationDataDto,
  StudyPacketDto,
} from '../../apis/hesi-app-facade-service/hesi-app-facade-service.dtos';
import {
  ALL_EXAM_GROUPS,
  ALL_STUDENTS,
  CUSTOM_DATE_RANGE,
  MULTIPLE_EXAM_GROUPS,
  TimeRangeMonthConstants
} from '../../pages/remediation-performance/remediation-performance.constants';
import {
  PerformanceAppExamResultsUrlRequestDto,
  PerformanceAppExamResultsUrlResponseDto
} from '../../apis/eols-app-link/eols-app-link.dtos';
import { APP_LINKING, USER_ROLES } from '../../constants/hesi.constants';
import { AppLinkOut } from '../../apis/eols-app-link/eols-app-link.models';

const fileName = 'app.state.actions.ts';
export const trackAction = (props: {
  appState: AppState;
  actionProps: AnalyticsActionProps;
}) => {
  const defaultActionProps = getDefaultActionProps(props.appState);
  const actionProps = scrubProps(props.actionProps.props);
  const _props = {
    ...defaultActionProps,
    ...actionProps
  };
  ELSLoggingService.info(fileName, `New Relic PageAction: ${props.actionProps.action}, ${JSON.stringify(_props)}`);
  if (!window.newrelic) {
    return;
  }
  window.newrelic.addPageAction(props.actionProps.action, _props);
};

const requestStart = (props: { setAppState: AppContextState['setAppState'] }) => {
  props.setAppState((prevState) => {
    return {
      ...prevState,
      pendingRequestCount: prevState.pendingRequestCount + 1
    };
  });
};

const requestEnd = (setAppState: AppContextState['setAppState']) => {
  setAppState((prevState) => {
    return {
      ...prevState,
      pendingRequestCount: prevState.pendingRequestCount - 1
    };
  });
};

const handleRequestError = (props: {
  appState: AppState;
  setAppState: AppContextState['setAppState'];
  error: Error;
  action: string;
  isTrackRequestCount: boolean;
}) => {

  const {
    error,
    action,
    isTrackRequestCount,
    setAppState
  } = props;

  const url = get(error, 'config.url', '');
  const method = get(error, 'config.method', '');
  const status = get(error, 'response.status', '');
  if (status === 400) {
    // Likely 400 error here due to overloaded headers
    removeCookies();
  }

  trackAction({
    appState: props.appState,
    actionProps: {
      action: AnalyticsAction.API_ERROR,
      props: {
        status,
        action,
        url,
        method
      }
    }
  });

  if (isTrackRequestCount) {
    requestEnd(setAppState);
  }

  return Promise.reject(error);
};

export const returnAppLink = (
  props: {
    appState: AppState;
    setAppState: AppContextState['setAppState'];
    linkId: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    returnPostBody: any;
  }
) => {
  const {
    appState,
    setAppState,
    linkId,
    returnPostBody
  } = props;

  return getLinkNavigation({ linkId, returnPostBody })
    .then(({ redirectUrl }) => {
      window.location.href = redirectUrl;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'returnAppLink',
      isTrackRequestCount: false
    }));
};

export const appLinkOut: AppLinkOut = (
  redirectUrl: string,
  isNewTab?: boolean,
) => {
  if (isNewTab) {
    window.open(redirectUrl, '_blank');
  } else {
    window.location.href = redirectUrl;
  }
};

export const fetchEvolveUsersAction = (props: {
  useCache: boolean;
  appState: AppState;
  setAppState: AppContextState['setAppState'];
  userId: string;
}) => {
  const {
    useCache,
    appState,
    setAppState,
    userId
  } = props;

  if (useCache && appState.evolveUser) {
    return Promise.resolve(appState.evolveUser);
  }
  requestStart({ setAppState });
  return fetchCrosswalkUser(userId, SystemType.EVOLVETYPE)
    .then((crosswalkUser) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          evolveUser: crosswalkUser
        };
      });
      requestEnd(setAppState);
      return crosswalkUser;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchCrosswalkUser',
      isTrackRequestCount: true
    }));
};

export const fetchAppLinkDataAction = (props: {
  appLinkId: string;
  appState: AppState;
  setAppState: AppContextState['setAppState'];
}) => {
  const {
    appLinkId,
    setAppState,
    appState
  } = props;

  requestStart({ setAppState });
  return fetchAppLinkData(appLinkId)
    .then((appLinkData) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          appLinkData,
          pendingRequestCount: prevState.pendingRequestCount - 1
        };
      });
      return appLinkData;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchAppLinkDataAction',
      isTrackRequestCount: true
    }));
};

export const fetchHashLinkAction = (props: {
  linkHash: string;
  appState: AppState;
  setAppState: AppContextState['setAppState'];
}) => {
  const {
    linkHash,
    appState,
    setAppState,
  } = props;

  requestStart({ setAppState });
  return fetchHashLink(linkHash)
    .then((hashLinkDto) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          appLinkCookies: hashLinkDto,
          pendingRequestCount: prevState.pendingRequestCount - 1
        };
      });
      return hashLinkDto;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchHashLinkAction',
      isTrackRequestCount: true
    }));

};

const postGroupFeatureFlagAction = (props: {
  eolsApp: string;
  featureName: string;
  group: string;
  featureValue: string;
}) => {
  const {
    eolsApp,
    featureName,
    group,
    featureValue
  } = props;
  return postGroupFeatureFlag(eolsApp, featureName, group, featureValue).then(() => {
    return {
      createdAt: null,
      eolsApp,
      featureName,
      featureValue,
      group,
      updatedAt: null
    };
  });
};

const fetchABFlavorAction = (props: {
  appState: AppState;
  setAppState: AppContextState['setAppState'];
  abTestFlag: FeatureFlagsGroupedDto;
}) => {
  const {
    appState,
    setAppState,
    abTestFlag
  } = props;
  const abTestFlavor = props.appState.abTestFlavors.find((flag) => {
    return flag.featureName === abTestFlag.featureName;
  });
  if (abTestFlavor) {
    return Promise.resolve(abTestFlavor);
  }
  requestStart({ setAppState });
  const app = `STUDENT_STUDY_${abTestFlag.featureName}`;
  const key = abTestFlag.featureName;
  return fetchGroupFeatureFlag(app, key, appState.courseSectionId)
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        const newFlavor = getRandomABTestFlavor(abTestFlag);
        if (!newFlavor) {
          ELSLoggingService.error(fileName, `AB test config missing for flag: ${key}`);
          return Promise.resolve({
            createdAt: null,
            eolsApp: app,
            featureName: key,
            featureValue: null,
            group: appState.courseSectionId,
            updatedAt: null
          });
        }
        return postGroupFeatureFlagAction({
          eolsApp: app,
          featureName: key,
          group: appState.courseSectionId,
          featureValue: newFlavor
        });
      }
      return Promise.reject(error);
    })
    .then((response) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          pendingRequestCount: prevState.pendingRequestCount - 1,
          abTestFlavors: [
            ...prevState.abTestFlavors,
            response
          ]
        };
      });
      return response;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchABFlavor',
      isTrackRequestCount: true

    }));
};

export const fetchAllAppFeatureFlagsAction = (props: {
  appState: AppState;
  setAppState: AppContextState['setAppState'];
}) => {

  const {
    setAppState,
    appState
  } = props;

  if (appState.featureFlagsGrouped) {
    return Promise.resolve(appState.featureFlagsGrouped);
  }
  requestStart({ setAppState });
  return fetchGroupedFeatureFlags().then((response) => {
    setAppState((prevState) => {
      return {
        ...prevState,
        featureFlagsGrouped: response,
        pendingRequestCount: prevState.pendingRequestCount - 1
      };
    });
    const promises = [];
    const activeABTests = getActiveABTestFlags(response, appState.courseSectionId);

    if (activeABTests && activeABTests.length) {
      activeABTests.forEach((activeABTest) => {
        promises.push(
          fetchABFlavorAction({
            abTestFlag: activeABTest,
            appState,
            setAppState
          })
        );
      });
    }

    if (promises.length) {
      return Promise.all(promises).then(() => {
        return response;
      });
    }

    return response;
  })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchAllAppFeatureFlagsAction',
      isTrackRequestCount: true

    }));
};

export const fetchCourseSectionAction = (props: {
  appState: AppState;
  setAppState: AppContextState['setAppState'];
  courseSectionId: string;
  useCache: boolean;
}) => {
  const {
    appState,
    setAppState,
    courseSectionId,
    useCache
  } = props;
  if (!courseSectionId) {
    return Promise.resolve(null);
  }
  if (useCache && appState.currentCourse) {
    return Promise.resolve(appState.currentCourse);
  }
  requestStart({ setAppState });
  return fetchCourseSectionService(courseSectionId)
    .then((response) => {
      setAppState((prevState) => {
        return {
          ...prevState,
          currentCourse: response,
          pendingRequestCount: prevState.pendingRequestCount - 1
        };
      });
      return response;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchCourseSectionAction',
      isTrackRequestCount: true
    }));
};

export const fetchAppFeatureSettingsAction = (
  props: {
    appState: AppState;
    setAppState: AppContextState['setAppState'];
  },
  courseSectionId: string
): Promise<AppFeatureSettingsDto> => {
  if (!courseSectionId) {
    return Promise.resolve(null);
  }

  const { appState, setAppState } = props;
  requestStart({ setAppState });

  return fetchAppFeatureSettings(courseSectionId)
    .then((response) => {
      requestEnd(setAppState);
      return response;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchAppFeatureSettingsAction',
      isTrackRequestCount: true
    }));
};

export const fetchRemediationDataAction = (
  props: {
    appState: AppState;
    setAppState: AppContextState['setAppState'];
    },
  courseSectionId: string,
  startDate: number,
  endDate: number
): Promise<RemediationDataDto> => {
  if (!courseSectionId) {
    return Promise.resolve(null);
  }

  const { appState, setAppState } = props;
  requestStart({ setAppState });

  return fetchRemediationData(courseSectionId, startDate, endDate)
    .then((response) => {
      requestEnd(setAppState);
      return response;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchRemedationDataAction',
      isTrackRequestCount: true
    }));
};

export const fetchCurrentCourseProgramDetailsAction = (
  props: {
    appState: AppState;
    setAppState: AppContextState['setAppState'];
  },
  courseSectionId: string
): Promise<CourseProgramDetailsDto> => {
  if (!courseSectionId) {
    return Promise.resolve(null);
  }

  const { appState, setAppState } = props;
  requestStart({ setAppState });

  return fetchCurrentCourseProgramDetails(courseSectionId)
    .then((response) => {
      requestEnd(setAppState);
      return response;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchCurrentCourseProgramDetailsAction',
      isTrackRequestCount: true
    }));
};

export const fetchNgnExamItemsAction = (
  props: {
    appState: AppState;
    setAppState: AppContextState['setAppState'];
  },
  courseSectionId: string,
  diffNgnReportingDataResponseExamIds: number[]
): Promise<NgnExamItemDto[]> => {
  if (!courseSectionId) {
    return Promise.resolve(null);
  }

  const { appState, setAppState } = props;
  requestStart({ setAppState });

  return fetchNgnExamItems(courseSectionId, diffNgnReportingDataResponseExamIds)
    .then((response) => {
      requestEnd(setAppState);
      return response;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchNgnExamItemsAction',
      isTrackRequestCount: true
    }));
};

export const fetchNgnExamRemediationAction = (
  props: {
    appState: AppState;
    setAppState: AppContextState['setAppState'];
  },
  courseSectionId: string,
  isAllowedPartialCreditScoring: boolean,
  diffNgnReportingDataResponseExamIds: number[]
): Promise<NgnRemediationItemDto[]> => {
  if (!courseSectionId) {
    return Promise.resolve(null);
  }

  const { appState, setAppState } = props;
  requestStart({ setAppState });

  return fetchNgnExamRemediation(courseSectionId, isAllowedPartialCreditScoring, diffNgnReportingDataResponseExamIds)
    .then((response) => {
      requestEnd(setAppState);
      return response;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchNgnExamRemediationAction',
      isTrackRequestCount: true
    }));
};

export const fetchStudyPacketsAction = (
  props: {
    appState: AppState;
    setAppState: AppContextState['setAppState'];
  },
  courseSectionId: string,
  examGroupIds: number[]
): Promise<StudyPacketDto[]> => {
  if (!courseSectionId) {
    return Promise.resolve(null);
  }

  const { appState, setAppState } = props;
  requestStart({ setAppState });

  return fetchStudyPackets(courseSectionId, examGroupIds)
    .then((response) => {
      requestEnd(setAppState);
      return response.studyPackets;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchStudyPacketsAction',
      isTrackRequestCount: true
    }));
};

const getPerformanceReportPage = (userRole: string) => {
  if (userRole === USER_ROLES.INSTRUCTOR) {
    return APP_LINKING.PERFORMANCE.PAGES.HESI_FACULTY_EXAM_RESULTS;
  }

  if (userRole === USER_ROLES.STUDENT) {
    return APP_LINKING.PERFORMANCE.PAGES.HESI_STUDENT_EXAM_RESULTS;
  }

  throw new Error('Unable to determine user role from SessionService');
};

export const fetchPerformanceAppExamResultsUrlAction = (
  props: {
    appState: AppState;
    setAppState: AppContextState['setAppState'];
  },
  courseSectionId: number,
  examTypeId: string,
  selectedExamGroupType: string | number,
  studentId: number,
  examStartDate: string,
  examEndDate: string,
  selectedDate: string,
  examGroupIds: number[]
): Promise<PerformanceAppExamResultsUrlResponseDto> => {
  if (!courseSectionId) {
    return Promise.resolve(null);
  }

  const parameters = {
    examTypeId,
    examGroupId: selectedExamGroupType,
    studentId: studentId || ALL_STUDENTS,
    // Both examStartDate and examEndDate will only be in body if CUSTOM_DATE_RANGE
    ...(!TimeRangeMonthConstants[selectedDate] && examStartDate && { examStartDate }),
    ...(!TimeRangeMonthConstants[selectedDate] && examEndDate && { examEndDate }),
    selectedDate: TimeRangeMonthConstants[selectedDate] ? TimeRangeMonthConstants[selectedDate].toString() : CUSTOM_DATE_RANGE,
    ...(selectedExamGroupType !== ALL_EXAM_GROUPS && { examGroupIds: selectedExamGroupType === MULTIPLE_EXAM_GROUPS ? examGroupIds : null }),
  };

  const body: PerformanceAppExamResultsUrlRequestDto = {
    courseSectionId,
    isReturnEnabled: true,
    performanceReportPage: getPerformanceReportPage(USER_ROLES.INSTRUCTOR),
    sourceApp: APP_LINKING.HESI_NG.NAME,
    parameters
  };

  const { appState, setAppState } = props;
  requestStart({ setAppState });

  return fetchPerformanceAppExamResultsUrl(body)
    .then((response) => {
      requestEnd(setAppState);
      return response;
    })
    .catch((e) => handleRequestError({
      appState,
      setAppState,
      error: e,
      action: 'fetchPerformanceAppExamResultsUrlAction',
      isTrackRequestCount: true
    }));
};
