import { CASE_STUDY_REQUIREMENT_TYPES } from '../../constants/hesi.constants';

export const ALL_EXAM_GROUPS = 'ALL_GROUPS';
export const MULTIPLE_EXAM_GROUPS = 'MULTIPLE_GROUPS';
export const ALL_STUDENTS = 'ALL_STUDENTS';

export const CaseStudyMessages: Record<string, string> = {
  [ALL_EXAM_GROUPS]: 'Students who have “Not Available” within their “Requirement met?” column didn’t receive case studies because they were either disabled during exam setup'
    + ' or not available for this exam',
  [CASE_STUDY_REQUIREMENT_TYPES.TWO_ATTEMPTS]: 'Student must complete all case studies at least once to meet the requirements.'
    + 'Their score on each case study is the average of two possible attempts.',
  [CASE_STUDY_REQUIREMENT_TYPES.MINIMUM_SCORE]: 'Student must reach a score of 80% on each case study to meet the requirement.',
};

// Date range constants
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';

export const CUSTOM_DATE_RANGE = 'CHOOSE_SPECIFIC_DATE_RANGE';
export const ONE_MONTH = '1_MONTH';
export const THREE_MONTHS = '3_MONTHS';
export const SIX_MONTHS = '6_MONTHS';
export const TWELVE_MONTHS = '12_MONTHS';

export const MIN_DATE = 946684800000;

export const TimeRangeMonthConstants: Record<string, number> = {
  [ONE_MONTH]: 1,
  [THREE_MONTHS]: 3,
  [SIX_MONTHS]: 6,
  [TWELVE_MONTHS]: 12
};

export const RemediationPerformanceTimeRanges = [
  {
    value: ONE_MONTH,
    name: 'Last month'
  },
  {
    value: THREE_MONTHS,
    name: 'Last 3 months'
  },
  {
    value: SIX_MONTHS,
    name: 'Last 6 months'
  },
  {
    value: TWELVE_MONTHS,
    name: 'Last 12 months'
  },
  {
    value: CUSTOM_DATE_RANGE,
    name: 'Choose specific date range'
  }
];

// NGN Exam Remediation constants
export const NGN_DISPLAY_TYPE_CASE = 'CASE';
export const NGN_DISPLAY_TYPE_STANDALONE = 'STANDALONE';

export const SYSTEM_PROCESSING = 'System Processing';
